import React, { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import html2canvas from 'html2canvas';
import axios from "axios";

const Articles = () => {
 
    const [postModal, setPostModal] = useState(false);
    const [noticeModal, setNoticeModal] = useState(false);
    const [notice_title, setNoticeTitle] = useState("");
    const [notices, setNotices] = useState([]);
    const [posts, setPosts] = useState({});  
    const [view, setNotice] = useState("");    
    const [post, setPost] = useState({});

    useEffect(() => {     
        try {
            axios.get(window.base_url + "documents/api").then(function (response) {
                if(response.data.notices != undefined) { 
                    setNotices(response.data.notices);
                }

                if(response.data.post_categories != undefined) { 
                    setPosts(response.data.post_categories);
                }
            }); 
        }
        catch (error) { 
            console.log(error);
        }
    }, []);


    const NoticeList = () => {
        return (  
            <div className='media-body'>
                <h4 className="notices">Supply Interruption Notices</h4>
                <ul> { notices.map((node) => {   
                        return(
                            <li key={node.notice_id} onClick={ () => ViewNotice(node.notice_id) }><a>{node.notice_title} {(node.areas[0] ? " in " + node.areas[0].sub_area_name : "")}, {node.area_display}</a></li>        
                        ) 
                    })}
                </ul> 
            </div>
        )
    } 

    const PostList = () => {
        return (  <>
            { Object.keys(posts).map((key, i) => { 
                    if(key != "General") 
                    {
                        return (
                        <Fragment key={i}> 
                        <div className='media-body'>
                            <h4 className='general'>{key}</h4> 
                            <ul>
                            { posts[key].map((node) => { 
                                return(
                                    <li key={node.post_id} onClick={ () => ViewPost(key, node.post_id) }><a>{node.post_title}</a></li>        
                                ) 
                            })} </ul>
                        </div>
                        </Fragment>
                        )
                    }
                })} 
            </>
        )
    }

    const GeneralList = () => {
        return (  <>
            { Object.keys(posts).map((key, i) => { 
                if(key == "General") 
                    {
                        return (
                        <Fragment key={i}> 
                        <div className='media-body'>
                            <h4 className='general'>{key}</h4> 
                            <ul>
                            { posts[key].map((node) => { 
                                return(
                                    <li key={node.post_id} onClick={ () => ViewPost(key, node.post_id) }><a>{node.post_title}</a></li>        
                                ) 
                            })} 
                            </ul>
                        </div>
                        </Fragment>
                        )
                    }
                })} 
            </>
        )
    }
              
     
    const ViewNotice = async (id) => {   
        let notice = {};      
        try {
            const response = await axios.get(window.base_url + "documents/notice_api/" + id).then(function (response) {
                if (response.data != undefined && response.data.results != undefined) {  
                    notice = response.data.results; 
                }
                else
                {
                    notice = notices.filter(item => item["notice_id"] == id)[0]; 
                }          
            });
        }
        catch (error) {}   
        setNoticeTitle(notice.notice_title); 
        let locations = {};
        let published = ""; 
        notice.areas.forEach(element => { 
            if(locations[element.area_display] != undefined)
            {
                locations[element.area_display].push(element.sub_area_name); 
            }
            else
            {
                locations[element.area_display] = [];
                locations[element.area_display].push(element.sub_area_name); 
            }
        });
        published += `<article class="documentation_info" id="share"><div class="notice">`;
        published += notice.category_id < 7 ? `<h1 class="title_notice">NOTICE<h1>`:
        `<h1 class="title_header">${notice.notice_title}<h1>`;
        
        published += `</div>`;

        if(notice.category_id == 14) {
            published += `<div class='notice_shutdown'>${notice.notice_shutdown}</div>`
        }

        if(notice.category_id == 6) {
            published += `<div class='notice_header'>${notice.category_name}</div>`;
        }

        if(notice.category_id == 5) {
            published += `<div class='notice_shutdown'>${notice.notice_title}</div>`;
        }
       
        if(notice.category_id != 14) {
            published += `<div class="notice_bg">`;
          
            if(notice.category_id == 6) {
                published += `<h6>${notice.notice_title}`; 
            }             
            
                if(locations != {})
                {
                    for(let location in locations)
                    { 
                        published += ` in `; 
                        locations[location].forEach((place, i) => {  
                            published += place + ', &nbsp;'; 
                        }); 

                        published += `(${location})`; 
                    }
                }

                if(notice.category_id == 6) {
                    published += ` as at ${readableTime(notice.notice_time)}.</h6>`; 
                }  
                 
                published += `<div class="notice_mid">

                ${notice.notice_text }`;

                if(notice.notice_image != "" 
                && (notice.notice_image.includes("jpg") || notice.notice_image.includes("jpeg") || notice.notice_image.includes("png"))) {
                    published += `<figure>
                    <img src=${notice.notice_image} alt='' class='img-fluid'/> 
                    </figure>`;
                }

                if(notice.category_id == 6) {
                    if(notice.notice_area_streets != "") {
                    published += `
                    <span class="notice_bottom">Areas/streets affected:</span>
                    <div class="notice_streets">${notice.notice_area_streets != null ? notice.notice_area_streets.replaceAll(",",", ") : ""}  and surrounding streets.</div>
                    <br> `;}
        
                    published += `<p>We apologise for any inconvenience and thank you for your patience.</p></div></div>`; 

                    if(notice.updates.length > 0)
                    {
                        notice.updates.forEach(item => {
                            published += `<div class='notice_header'>UPDATE</div>`;
                            published += `<div class="notice_bg"><h6>${item.update_title}</h6>`; 
                            published += `<div class="notice_mid">${item.update_text}</div>`;
                            if(item.update_image != "" 
                            && (item.update_image.includes("jpg") ||item.update_image.includes("jpeg") || item.update_image.includes("png"))) {
                                published += `<figure>
                                <img src=${item.update_image} alt='' class='img-fluid'/> 
                                </figure>`;
                            }
                            published += `</div>`; 
                        });
                    }                   
                } 
            }
            else
            {
                published += `<div class="notice_bg_planned">
    
                ${notice.notice_text }`;

                if(notice.notice_image != "" 
                    && (notice.notice_image.includes("jpg") || notice.notice_image.includes("jpeg") || notice.notice_image.includes("png"))) {
                        published += `<figure>
                        <img src=${notice.notice_image} alt='' class='img-fluid'/> 
                        </figure>`;
                    }
    

                published += `</div>`; 
            }

            published += `<div class="notice_footer">
                    <div class="row">
                        <div class="col-md-6"> 
                            <div class="footer_text">                      
                                <span class="color">Should you have any queries, please contact</span>
                                <span><strong>Tel:</strong> (013) 752 6839 . Whatsapp: 083 269 1456</span>
                                <span><strong>After Hours:</strong> (013) 752 6839 </span>
                                <span><strong>Email:</strong> enquiries@silulumanzicom </span>
                                <span><a href="https://www.facebook.com/silulumanzi/" target="_blank"><img src="/images/ficon.png" /> Silulumanzi </a></span>
                            </div>
                        </div>    
                        <div class="col-md-6">
                            <img src="/images/footer.png" class="img-fluid"/> 
                        </div>
                    </div>
                </div>
            </article>`;  
        setNotice(published);
        setNoticeModal(true); 
    }    


    const ViewPost = async (key, id) => {
    try {
        const response = await axios.get(window.base_url + "documents/post_api/" + id).then(function (response) {
            if (response.data != undefined && response.data.results != undefined) {  
                setPost(response.data.results);
                setPostModal(true); 
            }
            else
            {
            const view = posts[key].filter(item => item["post_id"] == id);  
                setPost(view[0]);
                setPostModal(true); 
            }          
        });
      }
      catch (error) {}  
    } 

    
    const handleDownloadImage = async () => {
        const element = document.getElementById('share'),
        canvas = await html2canvas(element),
        data = canvas.toDataURL('image/jpg'),
        link = document.createElement('a');
    
        link.href = data;
        link.download = notice_title + '.jpg';
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };


      const readableTime = (UNIX_timestamp) => {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds(); 
        var time =  hour + ':' + (min < 10 ? '0' : '') + min + ', ' + date + ' ' + month + ' ' + year;
        return time;
      }

  

    return (
        <section className='doc_features_area'>

            <Modal size="lg" show={postModal} onHide={() => setPostModal(false)} >
                <Modal.Header closeButton> <Modal.Title> {post.post_title} </Modal.Title>
                    </Modal.Header>
                <Modal.Body> <div dangerouslySetInnerHTML={{__html: post.post_text }} />
                { post.post_image != "" 
                && post.post_image != undefined && (post.post_image.includes("jpg") || post.post_image.includes("jpeg") || post.post_image.includes("png")) ? 
                <img src={post.post_image} alt='' className='img-fluid'/> : post.post_image != "" ? <a target="_blank" href={post.post_image} download="download" className="btn btn-sm btn-light">Download Attachment</a> : "" } 
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={noticeModal} onHide={() => setNoticeModal(false)} >
                <Modal.Header closeButton> <a className='btn btn-sm btn-light' onClick={handleDownloadImage}><FontAwesomeIcon icon={faDownload} /> Download</a></Modal.Header>
                <Modal.Body>
                <div dangerouslySetInnerHTML={{__html: view}} />                  
                </Modal.Body>
            </Modal>

            <div className='container'>
                <div className='doc_features_inner'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='media doc_features_item wow fadeInUp current' data-wow-delay='0.1s' data-wow-duration='0.5s'>                                
                                <NoticeList />  
                            </div>

                            <div className='media doc_features_item wow fadeInUp current' data-wow-delay='0.1s' data-wow-duration='0.5s'> 
                                <PostList/>  
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='media doc_features_item wow fadeInUp current' data-wow-delay='0.2s' data-wow-duration='1s'>                                                    
                                    <GeneralList /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}



export default Articles;